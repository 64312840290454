<template>
  <div class="create-que">
      <el-row class="type-choose">
        <!-- <el-col :span="6" offset="6">
          <el-card class="box-card" @mouseover.native="isHoverSurvey=true" @mouseout.native="isHoverSurvey=false" >
            <span ><h3>Questionnaire</h3></span>
            <div>
              <el-button type="primary" @click="createConfirm(1)">Create</el-button>
            </div>
          </el-card>
        </el-col> -->
        <el-col :span="6" offset="6">
          <el-card class="box-card"  @mouseover.native="isHoverTest=true" @mouseout.native="isHoverTest=false">
            <span><h3>Industry</h3></span>
            <div>
              <el-button type="danger" @click="createConfirm(2)">Create</el-button>
            </div>
          </el-card>
        </el-col>
        <el-col :span="6">
          <el-card class="box-card"  @mouseover.native="isHoverTest=true" @mouseout.native="isHoverTest=false">
            <span><h3>Company Type</h3></span>
            <div>
              <el-button type="danger" @click="createConfirm(3)">Create</el-button>
            </div>
          </el-card>
        </el-col>
        <el-col :span="6">
          <el-card class="box-card"  @mouseover.native="isHoverTest=true" @mouseout.native="isHoverTest=false">
            <span><h3>License</h3></span>
            <div>
              <el-button type="danger" @click="createConfirm(4)">Create</el-button>
            </div>
          </el-card>
        </el-col>
    </el-row>
    <el-button size="small" id="backB" type="primary" plain icon="el-icon-back" @click="goIndex">Back</el-button>
  </div>
</template>

<script>
// import user from "@/store/user";

export default {
  name: "createQue",
  data() {
    return {
      isHoverSurvey: false,
      isHoverTest: false,
      isHoverForm: false,
      isHoverVote: false,
      isHoverPunch: false,
      dialogVisible :false,
      quesType: 1,
      surveyTitle: "",
    }
  },
  methods: {
    createConfirm(tag){
      if(tag == 1)
        this.$router.push('/edit');
      if(tag == 2)
        this.$router.push('/edit_industry');
      if(tag == 3)
        this.$router.push('/edit_company');
      if(tag == 4)
        this.$router.push('/create_license');
    },
    goIndex: function () {
      this.$router.push('/index');
    },
  }
}
</script>

<style scoped>
.create-que{
  padding-top: 20px;
  width: 80%;
  text-align: center;
}

.create-que .describe{
  color: #808080;
}

.create-que .describe .el-row{
  margin: 3px;
}

.create-que .el-card__header {
  padding: 0;
  margin: 0;
}

.create-que .type-choose{
  padding-bottom: 22px;
  width: 100%;
  text-align: center;
}

.create-que .box-card:hover{
  margin-top: -5px;
  opacity: 0.85;
}

.create-que .box-card {
  margin-right: 5px;
  margin-left: 5px;
  text-align: center;
  width: 270px;
  height: 150px;
  transition: all .5s;
  cursor: pointer;
}

.create-que .image {
  width: 100%;
  height: 200px;
  display: block;
  margin: 0;
}

.create-que .el-button{
  width: 155px;
}

.create-que .input {
  width: 360px;
}

.create-que .el-button+.el-button {
  margin-left: 50px;
}

.create-que .bt-group {
  margin-bottom: 20px;
}
</style>